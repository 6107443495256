<template>
  <div :class="fieldData.attributes.class">
    <slot/>
  </div>
</template>
<script>
export default {
  name: 'Block',
  props: { fieldData: Object }
}
</script>
