<template>
  <div :class="attributes.class">
    <a :href="link.href" :class="link.class">{{link.label}}</a>
    <h5 class="size16">
      {{price.label}}
      <span :class="price.class">{{price.value}}</span>
    </h5>
    <a :href="cabinet.href" :class="cabinet.class">{{cabinet.label}}</a>
  </div>
</template>
<script>
export default {
  name: 'AdvertisementInfo',
  props: { elementData: Object },
  computed: {
    attributes() {
      return this.elementData.attributes;
    },
    link() {
      return this.elementData.link;
    },
    cabinet() {
      return this.elementData.cabinet;
    },
    price() {
      return this.elementData.price;
    },
  }
}
</script>
