import {mapGetters} from 'vuex';
const storeModule = require('../../../store/autoBuy');

const fields = {
	Title: require('./partials/fields/Title.vue').default,
	Block: require('./partials/fields/Block.vue').default,
	List: require('./partials/fields/List.vue').default,
};
const elements = {
	ImageElement: require('./partials/elements/Image.vue').default,
	ProcessElement: require('./partials/elements/ProcessElement.vue').default,
	ListElement: require('./partials/elements/ListElement.vue').default,
	AdvertisementInfo: require('./partials/elements/AdvertisementInfo.vue').default,
	Splitter: require('./partials/elements/Splitter.vue').default
};

export default {
	name: 'SecondStep',
	init({store, route: { to: { params: { id = 0 } = {} } = {}} = {}, context}) {
		context.response.redirect("/news/auto/227689/chto-takoe-avtovykup-i-kakuyu-mashinu-mozhno-prodat.html")

		store.registerModule('autoBuy', storeModule);
		let cookies = {};
		if (context) {
			cookies = context.request.headers.cookie;
		}

		if (_TARGET_ === 'server') {
			return store.dispatch('autoBuy/getStructure', {id, cookies});
		}
		return Promise.resolve();
	},
	metaInfo() {
		return {
			title: `AUTO.RIA™ — ${this.$t('Автовыкуп')}`,
			meta: [
				{name: 'description', content: ''},
				{name: 'keywords', content: ''},
				{property: 'og:title', content: ''},
				{property: 'og:description', content: ''},
				{name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
			],
			link: [
				{rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/'},
				{rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/'},
				{rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/'},
				{rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/'},
				{rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/'}
			]
		};
	},
	data() {
		return {
			a: 2
		};
	},
	components: {
		...elements,
		...fields
	},
	computed: {
		...mapGetters({
			structure: 'autoBuy/structure'
		})
	}
};
