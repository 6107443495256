<template>
  <div :class="attributes.class">
    <picture>
      <img :src="attributes.src"
           loading="lazy"
           onload="this.closest('.photo-responsive').classList.add('loaded');"
           alt="pic"
           :href="attributes.href"
      />
    </picture>
  </div>
</template>
<script>
export default {
  name: 'ImageElement',
  props: {elementData: Object},
  computed: {
    attributes() {
      const { elementData: { attributes = {} } = {} } = {} = this;
      return attributes;
    }
  }
}
</script>
