<template>
  <li :class="attributes.class">
    <div class="size13 date" v-if="dates.add">
      <span :class="{'date-color': addClass === 'active'}">{{ formatDate(dates.add) }}</span>
    </div>
  </li>
</template>
<script>
export default {
  name: 'ProcessElement',
  props: { elementData: Object },
  computed: {
    attributes() {
      const { elementData: { attributes = {} } = {} } = this;
      return attributes;
    }

  }
}
</script>
