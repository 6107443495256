<template>
  <div>
    <h1 :class="fieldData.attributes.class" v-if="size === 1">
      <slot>Нет шаблона</slot>
    </h1>
    <h2 :class="fieldData.attributes.class" v-else-if="size === 2">
      <slot>Нет шаблона</slot>
    </h2>
    <h3 :class="fieldData.attributes.class" v-else>
      <slot>Нет шаблона</slot>
    </h3>
  </div>
</template>
<script>
export default {
  name: 'Title',
  props: { fieldData: Object },
  computed: {
    size(){
      return this.fieldData.size;
    }
  }
}
</script>

