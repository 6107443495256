<template>
  <li :class="attributes.class">
    <div class="size13 date" v-if="date.value">
      <span :class="date.class">{{ date.value }}</span>
    </div>
    <template v-if="text.length">
      <div v-for="item in text" :class="item.class">
        {{ item.value }}
      </div>
    </template>
    <template v-else>
      {{ value }}
    </template>
  </li>
</template>
<script>
export default {
  name: 'ListElement',
  props: { elementData: Object },
  computed: {
    attributes() {
      const { elementData: { attributes = {} } = {} } = {} = this;
      return attributes;
    },
    value() {
      const { elementData: { value } = {} } = {} = this;
      return value;
    },
    text() {
      const { elementData: { text = [] } = {} } = {} = this;
      return text;
    },
    date() {
      const { elementData: { date = {} } = {}} = {} = this;
      return date;
    }
  }
}
</script>
