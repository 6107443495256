<template>
  <ul :class="attributes.class">
    <slot />
  </ul>
</template>
<script>
export default {
  name: 'List',
  props: { fieldData: Object },
  computed: {
    attributes() {
      return this.fieldData.attributes;
    }
  }
}
</script>
